import Cookie from 'js-cookie';

const Token = 'navigation-server-token';
const requestModuleCode = 'auth-module-code'

export function getToken() {
    return Cookie.get(Token);
}

export function setToken(token) {
    console.log(token)
    return Cookie.set(Token, token)
}

export function removeToken() {
    return Cookie.remove(Token)
}

export function getRefreshToken() {
    // return Cookie.get(RefreshTokenKey)
}


export function setAuthCode(code) {
    // return Cookie.set(Code, code)
}

export function getAuthCode() {
    // return Cookie.get(Code)
}

export function setAuthState(state) {
    // return Cookie.set(State, state)
}

export function getAuthState() {
    // return Cookie.get(State)
}

export function getAuthModuleCode() {
    return Cookie.get(requestModuleCode);
}

export function setRedirectMoudleCode(code) {
    return Cookie.set(requestModuleCode, code)
}
