import login from '../components/login.vue'
import layout from '../components/layout/index.vue'

export const commonRoutes = [ // 不需要权限的页面
    {
        path: '/',
        name: '',
        redirect: to => {
            return '/websiteNavigation/websiteModule'
        },
        hidden: true,
    },
    {
        path: '/login',
        name: 'login',
        component: login,
        hidden: true
    },
    {
        path: '/login2',
        name: 'login',
        component: login,
        hidden: true
    },
    {
        path: '/404',
        component: () => import('@/views/404.vue'),
        hidden: true
    },
]
export const routerMap = [ // 需要权限页面，需要根据后端接口返回的权限做筛选
    {
        path: '/websiteNavigation',
        name: 'websiteNavigation',
        component: layout,
        meta: {title: '应用服务'},
        children: [
            {
                path: 'websiteModule',
                name: 'websiteModule',
                component: () => import('../components/websiteNavigation/index.vue'),
                meta: {title: '应用导航', noCache: true}
            },
        ]
    },
    {
        path: '/area',
        name: 'area',
        meta: {title: '区级中台'},
        promote: true,
        component: () => import('../components/layout/empty'),
        children: [
            {
                path: 'home',
                name: 'home',
                component: () => import('../components/layout/area/index'),
                meta: {title: '首页'},
                promote: true,
                children: [
                    {
                        path: 'index',
                        name: 'index',
                        component: () => import('../components/home/index.vue'),
                        meta: {title: '首页', noCache: true}
                    },
                ]
            },
            {
                path: 'joinAnalysis',
                name: 'joinAnalysis',
                component: () => import('../components/layout/area/index'),
                meta: {title: '接入分析'},
                promote: true,
                children: [
                    {
                        path: 'joinAnalysisIndex',
                        name: 'joinAnalysisIndex',
                        component: () => import('../components/home/component/joinAnalysis'),
                        meta: {title: '接入分析', noCache: true}
                    },
                ]
            },
            {
                path: 'village',
                name: 'village',
                component: () => import('../components/layout/area/index'),
                meta: {title: '小区数据'},
                children: [
                    {
                        path: 'villageAnalyze',
                        name: 'villageAnalyze',
                        component: () => import('../components/village/villageAnalyze.vue'),
                        meta: {title: '小区分析', noCache: true}
                    },
                    {
                        path: 'villageData',
                        name: 'villageData',
                        component: () => import('../components/village/villageData.vue'),
                        meta: {title: '小区实时数据', noCache: true}
                    },
                    {
                        path: 'villageDataAlarm',
                        name: 'villageDataAlarm',
                        component: () => import('../components/village/dataAlarm.vue'),
                        meta: {title: '小区数据告警', noCache: true}
                    },
                    {
                        path: 'villageDetail',
                        name: 'villageDetail',
                        hidden: true,
                        component: () => import('../components/village/villageDetail.vue'),
                        meta: {title: '小区详情', noCache: true}
                    },
                    {
                        path: 'cleanHouseList',
                        name: 'cleanHouseList',
                        hidden: true,
                        component: () => import('../components/cleanHouse/cleanHouseList.vue'),
                        meta: {title: '小区清洁屋', noCache: true}
                    },
                ]
            },
            {
                path: 'disposalSite',
                name: 'disposalSite',
                component: () => import('../components/layout/area/index'),
                meta: {title: '处置站点'},
                children: [
                    {
                        path: 'disposalSiteAnalyze',
                        name: 'disposalSiteAnalyze',
                        component: () => import('../components/disposalSite/disposalSiteAnalyze.vue'),
                        meta: {title: '处置站点分析', noCache: true}
                    },
                    {
                        path: 'disposalSiteData',
                        name: 'disposalSiteData',
                        component: () => import('../components/disposalSite/disposalSiteData.vue'),
                        meta: {title: '处置站点实时数据', noCache: true}
                    },
                    {
                        path: 'streetAnalyze',
                        name: 'streetAnalyze',
                        component: () => import('../components/street/streetAnalyze.vue'),
                        meta: {title: '街道分析', noCache: true}
                    },
                ]
            },
            {
                path: 'report',
                name: 'report',
                component: () => import('../components/layout/area/index'),
                meta: {title: '数据报表'},
                children: [
                    {
                        path: 'reportOfDay',
                        name: 'reportOfDay',
                        component: () => import('../components/report/day.vue'),
                        meta: {title: '计量采集日报表', noCache: true}
                    },
                    {
                        path: 'reportOfWeek',
                        name: 'reportOfWeek',
                        component: () => import('../components/report/week.vue'),
                        meta: {title: '计量采集周报表', noCache: true}
                    },
                    {
                        path: 'reportOfMonth',
                        name: 'reportOfMonth',
                        component: () => import('../components/report/month.vue'),
                        meta: {title: '计量采集月报表', noCache: true}
                    },
                    {
                        path: 'reportOfYear',
                        name: 'reportOfYear',
                        component: () => import('../components/report/year.vue'),
                        meta: {title: '计量采集年报表', noCache: true}
                    },
                    {
                        path: 'reportOfNoDataVillage',
                        name: 'reportOfNoDataVillage',
                        component: () => import('../components/report/noDataVillage'),
                        meta: {title: '无数据小区详情报表', noCache: true}
                    },
                    // {
                    //     path: 'reportOfNoDataVillageCount',
                    //     name: 'reportOfNoDataVillageCount',
                    //     component: () => import('../components/report/noDataVillageCount'),
                    //     meta: {title: '无数据小区统计报表', noCache: true}
                    // },
                ]
            },
            {
                path: 'monitor',
                name: 'monitor',
                component: () => import('../components/layout/area/index'),
                meta: {title: '监控平台'},
                children: [
                    {
                        path: 'monitorList',
                        name: 'monitorList',
                        component: () => import('../components/monitor/monitorList'),
                        meta: {title: '监控设备列表', noCache: true}
                    },
                    {
                        path: 'monitorPreview',
                        name: 'monitorPreview',
                        hidden: true,
                        component: () => import('../components/monitor/monitorPreview'),
                        meta: {title: '监控设备预览', noCache: true}
                    },
                    {
                        path: 'monitorPlayback',
                        name: 'monitorPlayback',
                        hidden: true,
                        component: () => import('../components/monitor/monitorPlayback'),
                        meta: {title: '监控设备回放', noCache: true}
                    },
                ]
            },
            {
                path: 'device',
                name: 'device',
                component: () => import('../components/layout/area/index'),
                meta: {title: '设备地图'},
                children: [
                    {
                        path: 'deviceMap',
                        name: 'deviceMap',
                        component: () => import('../components/deviceMap/index'),
                        meta: {title: '设备地图', noCache: true}
                    }
                ]
            },
        ]
    },
    {
        path: '/street',
        name: 'street',
        meta: {title: '街道中台'},
        promote: true,
        component: () => import('../components/layout/empty'),
        children: [
            {
                path: 'streetHome',
                name: 'streetHome',
                component: () => import('../components/layout/street/index'),
                promote: true,
                meta: {title: '首页'},
                children: [
                    {
                        path: 'streetIndex',
                        name: 'streetIndex',
                        component: () => import('../components/home/street/index'),
                        meta: {title: '首页', noCache: true}
                    }
                ]
            },
            {
                path: 'streetAnalysis',
                name: 'streetAnalysis',
                component: () => import('../components/layout/street/index'),
                promote: true,
                meta: {title: '街道分析'},
                children: [
                    {
                        path: 'streetAnalysisIndex',
                        name: 'streetAnalysisIndex',
                        component: () => import('../components/streetAnalysis/index'),
                        meta: {title: '街道分析', noCache: true}
                    }
                ]
            },
            {
                path: 'centerAnalysis',
                name: 'centerAnalysis',
                component: () => import('../components/layout/street/index'),
                promote: true,
                meta: {title: '小区分析'},
                children: [
                    {
                        path: 'centerAnalysisIndex',
                        name: 'centerAnalysisIndex',
                        component: () => import('../components/centerAnalysis/index'),
                        meta: {title: '小区分析', noCache: true}
                    }
                ]
            },
            {
                path: 'actualStreet',
                name: 'actualStreet',
                component: () => import('../components/layout/street/index'),
                promote: true,
                meta: {title: '实时数据'},
                children: [
                    {
                        path: 'actualIndexStreet',
                        name: 'actualIndexStreet',
                        component: () => import('../components/actual/index'),
                        meta: {title: '实时数据', noCache: true}
                    }
                ]
            },
            {
                path: 'collection',
                name: 'collection',
                component: () => import('../components/layout/street/index'),
                promote: true,
                meta: {title: '清洁屋列表'},
                children: [
                    {
                        path: 'collectionIndex',
                        name: 'collectionIndex',
                        component: () => import('../components/collection/index'),
                        meta: {title: '清洁屋列表', noCache: true}
                    }
                ]
            },
            {
                path: 'deviceStreet',
                name: 'deviceStreet',
                component: () => import('../components/layout/street/index'),
                promote: true,
                meta: {title: '称重设备列表'},
                children: [
                    {
                        path: 'deviceIndexStreet',
                        name: 'deviceIndexStreet',
                        component: () => import('../components/device/index'),
                        meta: {title: '称重设备列表', noCache: true}
                    }
                ]
            },
            {
                path: 'streetCompare',
                name: 'streetCompare',
                component: () => import('../components/layout/street/index'),
                promote: true,
                meta: {title: '小区对比'},
                children: [
                    {
                        path: 'streetCompareIndex',
                        name: 'streetCompareIndex',
                        component: () => import('../components/compare/index'),
                        meta: {title: '小区对比', noCache: true}
                    }
                ]
            },
            {
                path: 'monitorStreet',
                name: 'monitorStreet',
                component: () => import('../components/layout/street/index'),
                meta: {title: '监控平台'},
                children: [
                    {
                        path: 'monitorListStreet',
                        name: 'monitorListStreet',
                        component: () => import('../components/monitor-street/monitorList'),
                        meta: {title: '监控设备列表', noCache: true}
                    },
                    {
                        path: 'monitorPreviewStreet',
                        name: 'monitorPreviewStreet',
                        hidden: true,
                        component: () => import('../components/monitor-street/monitorPreview'),
                        meta: {title: '监控设备预览', noCache: true}
                    },
                    {
                        path: 'monitorPlaybackStreet',
                        name: 'monitorPlaybackStreet',
                        hidden: true,
                        component: () => import('../components/monitor-street/monitorPlayback'),
                        meta: {title: '监控设备回放', noCache: true}
                    },
                ]
            },
            {
                path: 'streetReport',
                name: 'streetReport',
                component: () => import('../components/layout/street/index'),
                meta: {title: '报表'},
                children: [
                    {
                        path: 'streetDay',
                        name: 'streetDay',
                        component: () => import('../components/streetReport/streetDay.vue'),
                        meta: {title: '日报表', noCache: true}
                    },
                    {
                        path: 'streetMonth',
                        name: 'streetMonth',
                        component: () => import('../components/streetReport/streetMonth.vue'),
                        meta: {title: '月报表', noCache: true}
                    },
                    {
                        path: 'streetYear',
                        name: 'streetYear',
                        component: () => import('../components/streetReport/streetYear.vue'),
                        meta: {title: '年报表', noCache: true}
                    }
                ]
            },
        ]
    },
    {
        path: '/community',
        name: 'community',
        meta: {title: '小区中台'},
        promote: true,
        component: () => import('../components/layout/empty'),
        children: [
            {
                path: 'centerHome',
                name: 'centerHome',
                component: () => import('../components/layout/community/index'),
                promote: true,
                meta: {title: '首页'},
                children: [
                    {
                        path: 'centerIndex',
                        name: 'centerIndex',
                        component: () => import('../components/home/community/index'),
                        meta: {title: '首页', noCache: true}
                    }
                ]
            },
            {
                path: 'actual',
                name: 'actual',
                component: () => import('../components/layout/community/index'),
                promote: true,
                meta: {title: '实时数据'},
                children: [
                    {
                        path: 'actualIndex',
                        name: 'actualIndex',
                        component: () => import('../components/actual/index'),
                        meta: {title: '实时数据', noCache: true}
                    }
                ]
            },
            {
                path: 'alarm',
                name: 'alarm',
                component: () => import('../components/layout/community/index'),
                promote: true,
                meta: {title: '数据告警'},
                children: [
                    {
                        path: 'alarmIndex',
                        name: 'alarmIndex',
                        component: () => import('../components/alarm/index'),
                        meta: {title: '数据告警', noCache: true}
                    }
                ]
            },
            {
                path: 'center',
                name: 'center',
                component: () => import('../components/layout/community/index'),
                promote: true,
                meta: {title: '小区分析'},
                children: [
                    {
                        path: 'centersIndex',
                        name: 'centersIndex',
                        component: () => import('../components/center/index'),
                        meta: {title: '小区分析', noCache: true}
                    }
                ]
            },
            {
                path: 'report',
                name: 'report',
                component: () => import('../components/layout/community/index'),
                promote: true,
                meta: {title: '计量采集日报表'},
                children: [
                    {
                        path: 'day',
                        name: 'day',
                        component: () => import('../components/report/day'),
                        meta: {title: '计量采集日报表', noCache: true}
                    }
                ]
            },
        ],
    },
    {
        path: '/viewport/viewport',
        name: 'viewport',
        component: () => import('../components/viewport/index.vue'),
        meta: {title: '大屏'},
    },
    {
        path: '/street/viewport/viewport',
        name: 'viewport',
        component: () => import('../components/viewport/street/index.vue'),
        meta: {title: '大屏'},
    },
    {
        path: '/user',
        name: 'user',
        meta: {title: '用户管理系统'},
        promote: true,
        component: () => import('../components/layout/empty'),
        children: [
            {
                path: 'managementHome',
                name: 'managementHome',
                component: () => import('../components/layout/user/index'),
                promote: true,
                meta: {title: '用户管理'},
                children: [
                    {
                        path: 'managementIndex',
                        name: 'managementIndex',
                        component: () => import('../components/home/user/index'),
                        meta: {title: '用户管理', noCache: true}
                    }
                ]
            },
            {
                path: 'roleManagement',
                name: 'roleManagement',
                component: () => import('../components/layout/user/index'),
                promote: true,
                meta: {title: '角色管理'},
                children: [
                    {
                        path: 'roleManagementIndex',
                        name: 'roleManagementIndex',
                        component: () => import('../components/roleMgt/index'),
                        meta: {title: '角色管理', noCache: true}
                    }
                ]
            },
        ]
    },
    {
        path: '/management',
        name: 'management',
        meta: {title: '管理系统'},
        promote: true,
        component: () => import('../components/layout/empty'),
        children: [
            {
                path: 'base',
                name: 'base',
                component: () => import('../components/layout/management/index'),
                meta: {title: '基础模块'},
                children: [
                    {
                        path: 'residentialMgt',
                        name: 'residentialMgt',
                        component: () => import('../components/residentialMgt/index.vue'),
                        meta: {title: '小区站点', keepAlive: true}
                    },
                    {
                        path: 'collectionPointMgt',
                        component: () =>
                            import('../components/collectionPointMgt/index.vue'),
                        name: 'collectionPointMgt',
                        meta: {title: '清洁屋管理', icon: 'home', keepAlive: true}
                    },
                    {
                        path: 'equipmentMgt',
                        name: 'equipmentMgt',
                        component: () => import('../components/equipmentMgt/index.vue'),
                        meta: {title: '设备管理', keepAlive: true}
                    },
                    {
                        path: 'monitorList',
                        name: 'monitorList1',
                        component: () => import('../components/monitor1/monitorList'),
                        meta: {title: '监控管理', keepAlive: true}
                    },
                    {
                        path: 'ksEquipmentReport',
                        name: 'ksEquipmentReport',
                        hidden: true,
                        component: () => import ('../components/ksEquipmentReport/index.vue'),
                        meta: {title: '昆山地磅信息报表', noCache: true}
                    },
                ]
            },
            {
                path: 'factoryMgt',
                name: 'factoryMgt',
                component: () => import('../components/layout/management/index'),
                meta: {title: '厂商管理'},
                children: [
                    {
                        path: 'factoryList',
                        name: 'factoryList',
                        component: () => import('../components/factoryMgt/factoryList'),
                        meta: {title: '厂商列表', keepAlive: true}
                    },
                    {
                        path: 'factoryEquipment',
                        name: 'factoryEquipment',
                        component: () => import('../components/factoryMgt/factoryEquipment'),
                        meta: {title: '厂商设备', keepAlive: true}
                    },
                    {
                        path: 'factoryStatistics',
                        name: 'factoryStatistics',
                        component: () => import('../components/factoryMgt/factoryStatistics'),
                        meta: {title: '厂商统计'}
                    },
                ]
            },

            {
                path: 'actualMgt',
                name: 'actualMgt',
                component: () => import('../components/layout/management/index'),
                meta: {title: '数据管理'},
                children: [
                    {
                        path: 'comprehensive',
                        name: 'comprehensive',
                        component: () => import('../components/actualMgt/index'),
                        meta: {title: '生活垃圾', keepAlive: true}
                    },
                    {
                        path: 'site',
                        name: 'site',
                        component: () => import('../components/actualMgt/site'),
                        meta: {title: '就地处置', keepAlive: true}
                    },
                    {
                        path: 'residential',
                        name: 'residential',
                        component: () => import('../components/residentialcompareMgt/index'),
                        meta: {title: '数据对比', noCache: true}
                    },
                    {
                        path: 'restrictData',
                        name: 'restrictData',
                        component: () => import('../components/route/restrictData'),
                        meta: {title: '异常数据', keepAlive: true}
                    },
                    {
                        path: 'restrict',
                        name: 'restrict',
                        hidden: true,
                        component: () => import('../components/route/restrict'),
                        meta: {title: '数据采集限制查询', noCache: true}
                    },
                    {
                        path: 'devicePush',
                        name: 'devicePush',
                        component: () => import('../components/actualMgt/devicePush'),
                        meta: {title: '数据推送', keepAlive: true}
                    }
                ]
            },
            {
                path: 'route',
                name: 'route',
                component: () => import('../components/layout/management/index'),
                meta: {title: '系统管理'},
                children: [
                    {
                        path: 'userList',
                        name: 'userList',
                        component: () => import('../components/route/userList'),
                        meta: {title: '用户管理', keepAlive: true}
                    },
                    {
                        path: 'roleList',
                        name: 'roleList',
                        component: () => import('../components/route/roleList'),
                        meta: {title: '角色管理', keepAlive: true}
                    },
                    {
                        path: 'permissionList',
                        name: 'permissionList',
                        component: () => import('../components/route/permissionList'),
                        meta: {title: '权限管理', keepAlive: true}
                    },
                    {
                        path: 'module',
                        name: 'module',
                        component: () => import('../components/module/moduleList.vue'),
                        meta: {title: '模块管理', keepAlive: true}
                    },
                    {
                        path: 'pageList',
                        name: 'pageList',
                        component: () => import('../components/route/routeList'),
                        meta: {title: '路由管理', keepAlive: true}
                    },
                    {
                        path: 'divisionMgt',
                        component: () =>
                            import('../components/divisionMgt/index.vue'),
                        name: 'divisionMgt',
                        meta: {title: '行政区划', icon: 'home', keepAlive: true}
                    }
                ]
            },
            {
                path: 'develop',
                name: 'develop',
                component: () => import('../components/layout/management/index'),
                meta: {title: '开发模块'},
                children: []
            },
            {
                path: 'monitor',
                name: 'monitor',
                hidden: true,
                component: () => import('../components/layout/management/index'),
                meta: {title: '监控设备'},
                children: [
                    {
                        path: 'monitorPreview',
                        name: 'monitorPreview1',
                        hidden: true,
                        component: () => import('../components/monitor1/monitorPreview'),
                        meta: {title: '监控设备预览', noCache: true}
                    },
                    {
                        path: 'monitorPlayback',
                        name: 'monitorPlayback1',
                        hidden: true,
                        component: () => import('../components/monitor1/monitorPlayback'),
                        meta: {title: '监控设备回放', noCache: true}
                    },
                    {
                        path: 'monitorPreview2',
                        name: 'monitorPreview21',
                        hidden: true,
                        component: () => import('../components/monitor1/monitorPreview2'),
                        meta: {title: '监控设备预览2', noCache: true}
                    }
                ]
            },
        ]
    },
    {
        path: '/viewport/areaMapView',
        name: 'areaMapView',
        component: () => import('../components/areaMapView/index.vue'),
        meta: {title: '大屏'},
    },
    {
        path: '/data-service',
        name: 'dataService',
        meta: {title: '数据服务'},
        promote: true,
        component: () => import('../components/layout/empty'),
        children: [
            {
                path: 'serviceHome',
                name: 'serviceHome',
                component: () => import('../components/layout/dataService/index'),
                promote: true,
                meta: {title: '数据服务'},
                children: [
                    {
                        path: 'serviceIndex',
                        name: 'serviceIndex',
                        component: () => import('../components/dataService/home/index'),
                        meta: {title: '数据服务', noCache: true}
                    }
                ]
            },
        ]
    }
]