import service from './network';

export function queryTrashCollectSum(data) {
    return service.request({
        url: '/zhongtai/index/query',
        method: 'post',
        data
    })
}

export function queryOverviewOfStreetCollect(data) {
    return service.request({
        url: '/zhongtai/index/queryStreet',
        method: 'post',
        data
    })
}

export function queryMonthTrash(data) {
    return service.request({
        url: '/zhongtai/index/queryMonth',
        method: 'post',
        data
    })
}

export function queryTenDay(data) {
    return service.request({
        url: '/zhongtai/index/queryTenDay',
        method: 'post',
        data
    })
}

export function queryDataCount(data) {
    return service.request({
        url: '/center/date/count',
        method: 'get',
        params: data
    })
}

export function queryUserModelPermissionApi(data) {
    return service.request({
        url: '/user/model/permission/query',
        method: 'post',
        data
    })
}

/**
 *街道首页-街道信息
 * @param {} data
 * @returns
 */
export function queryStreetInfoApi(data, code) {
    return service.request({
        url: '/street/home/info',
        method: 'get',
        params: data,
        headers: {'moduleToken': code}
    })
}

/**
 *小区首页-小区四分类信息
 * @param {} data
 * @returns
 */
export function queryCenterTypeApi(data, code) {
    return service.request({
        url: '/street/home/query/type',
        method: 'post',
        data,
        headers: {'moduleToken': code}
    })
}

/**
 *街道首页-小接入情况
 * @param {} data
 * @returns
 */
export function queryStreetAccessApi(data, code) {
    return service.request({
        url: '/street/home/access/info',
        method: 'post',
        data,
        headers: {'moduleToken': code}
    })
}

/**
 *街道首页-小区数据告警
 * @param {} data
 * @returns
 */
export function queryStreetAlarmApi(data, code) {
    return service.request({
        url: '/street/home/exception',
        method: 'post',
        data,
        headers: {'moduleToken': code}
    })
}

/**
 *街道首页-热力图
 * @param {} data
 * @returns
 */
export function queryStreetHeatMapApi(data, code) {
    return service.request({
        url: '/street/home/chart/hot',
        method: 'post',
        data,
        headers: {'moduleToken': code}
    })
}

/**
 *小区首页-折线图
 * @param {} data
 * @returns
 */
export function queryStreetLineMapApi(data, code) {
    return service.request({
        url: '/street/home/chart/trend',
        method: 'post',
        data,
        headers: {'moduleToken': code}
    })
}

/**
 *小区首页-实时数据
 * @param {} data
 * @returns
 */
export function queryCenterActualApi(data, code) {
    return service.request({
        url: '/site/analysis/realTime',
        method: 'post',
        data,
        headers: {'moduleToken': code}
    })
}

/**
 *小区首页-垃圾类别
 * @param {} data
 * @returns
 */
export function queryCenterGarbageTypeApi(data, code) {
    return service.request({
        url: '/site/analysis/garbage/type',
        method: 'get',
        data,
        headers: {'moduleToken': code}
    })
}

/**
 *小区首页-小区信息
 * @param {} data
 * @returns
 */
export function queryCenterInfoApi(data, code) {
    return service.request({
        url: '/site/home/info',
        method: 'get',
        params: data,
        headers: {'moduleToken': code}
    })
}

/**
 *小区首页-小区清洁屋
 * @param {} data
 * @returns
 */
export function queryCenterHouseApi(data, code) {
    return service.request({
        url: '/site/home/house',
        method: 'post',
        data,
        headers: {'moduleToken': code}
    })
}

/**
 *小区首页-小区数据告警
 * @param {} data
 * @returns
 */
export function queryCenterAlarmApi(data, code) {
    return service.request({
        url: '/site/home/house/alarm',
        method: 'post',
        data,
        headers: {'moduleToken': code}
    })
}

/**
 *小区首页-热力图
 * @param {} data
 * @returns
 */
export function queryCenterHeatMapApi(data, code) {
    return service.request({
        url: '/site/home/chart/hot',
        method: 'post',
        data,
        headers: {'moduleToken': code}
    })
}

/**
 *小区首页-折线图
 * @param {} data
 * @returns
 */
export function queryCenterLineMapApi(data, code) {
    return service.request({
        url: '/site/home/chart/trend',
        method: 'post',
        data,
        headers: {'moduleToken': code}
    })
}