import service from './network';

export function queryRegionNodeChild(data) {
    return service.request({
        url: '/region/node/child',
        method: 'get',
        params: data
    })
}

export function queryModule(data) {
    if (data)
        return service.request({
            url: '/module/query/' + data,
            method: 'get',
        })
    else {
        return new Promise((resolve) => {
            resolve({
                result: {}
            })
        })
    }
}
