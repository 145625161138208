// import Network from './network';
import service from './network';

const loginBaseURL = 'https://www.zhongshihudong.com/gateway'
const apiBaseUrl = ''
// 封装各种接口请求
// export const 接口名 = () => Network.get('/路由',参数对象);
// export const login = params => Network.post('/security/authority/login', params);
/**
 * 获取用户访问模块
 * @param {*} data 
 * @returns 
 */
export function queryUserAuthModuleApi(data){
    return service.request({
        url: '/module/user/query',
        method: 'post',
        data
    })
}

export function getAccessTokenApi(data){
    return service.request({
        url: '/user/login',
        method: 'post',
        data: data
    })
}

export function updateUserVisitApi(data) {
    return service.request({
        url: '/history/module/visit/' + data,
        method: 'put',
        
    })
}

export function queryUserInfoApi(data) {
    return service.request({
        url: '/user/info',
        method: 'get',
        params: data
    })
}

export function queryRecentUserAuthModuleApi(data) {
    return service.request({
        url: '/history/module/lately',
        method: 'get',
        params: data
    })
}

export function loginByAccount(data){
    return service.request({
        url: '/security/authority/login',
        method: 'post',
        data
    })
}

export function queryTruckActual(data){
    return service.request({
        url: '/truck/collect/query',
        method: 'post',
        data
    })
}

export function queryTruckModule(data){
    return service.request({
        url: '/truck/collect/module',
        method: 'get',
        data
    })
}

export function queryTruckType(data){
    return service.request({
        url: '/truck/collect/type',
        method: 'post',
        data
    })
}

export function queryTruckTrend(data){
    return service.request({
        url: '/truck/collect/trend',
        method: 'post',
        data
    })
}

export function queryTruckMap(data){
    return service.request({
        url: '/truck/collect/map',
        method: 'get',
        data
    })
}

export function queryTruckCenterList(data){
    return service.request({
        url: 'truck/collect/user/center/list',
        method: 'get',
        data
    })
}

