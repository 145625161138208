import Vue from 'vue';
import Router from 'vue-router';
import {commonRoutes} from './router'
import NProgress from 'nprogress'
import {getToken, setToken} from '@/utils/auth'
import util from '@/utils/util'
// 路由注册
import store from '../store';

const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
Vue.use(Router)

console.log(commonRoutes, 'comm')
const router = new Router({
    mode: 'hash',
    routes: commonRoutes,
})

router.beforeEach((to, from, next) => {
    console.log(to)
    NProgress.start()
    // console.log('beforeEach开始')

    if (getToken()) {
        if (to.path === '/login') {
            next()
        } else {
            if (from.query.moduleCode && !to.query.moduleCode) {
                console.log(from.query, to.query)
                next({
                    ...to, replace: true,
                    query: {
                        ...to.query,
                        moduleCode: from.query.moduleCode
                    }
                })
            }
            if (to.query.moduleCode) {
                store.dispatch('user/setModule', {...store.getters.module, moduleCode: to.query.moduleCode});
            }
            // console.log('如果有token，开始获取接口路由')
            if (!store.state.router.hasGetRules) {
                store.dispatch('getRules').then(rules => { // 调用接口 获取后端返回的路由权限页面/按钮/组件
                    store.dispatch('concatRoutes', rules.page).then(routes => { // 合并页面
                        // console.log(routes, 'router')
                        router.addRoutes(routes)
                        // console.log(router, '已有路由权限列表')
                        // console.log(to, '即将切换到新路由')
                        next({...to, replace: true,}) // ...to避免routes还没有合并完整，replace:true路由跳转和替换之前路由
                    }).catch((err) => {
                        console.log(err, '拼接路由失败')
                        next({path: '/login', name: 'login'})
                    })
                })
            } else {
                next()
            }
        }
    } else {
        if (to.path === '/login') {
            next()
        } else {
            util.getAccessToken(to.query.code, to.path, to.query.state).then(res => {
                next()
                setToken('token123')
                window.location.reload()
            })
            setToken('token123')
            window.location.reload()
        }

    }

})

router.afterEach(() => {
    NProgress.done() // finish progress bar
})
export default router
