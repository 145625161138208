import { getToken, setToken, removeToken } from '@/utils/auth'
// import { loginByAccount, queryUserInfoApi } from '@/api/index'
// 村赤用户令牌和角色信息
const state ={
    token: getToken(),
    roles: [], //角色
    module: {},
    streetList: [],
}

const mutations ={
    SET_TOKEN: (state,token) => {
        state.token = token;
        console.log(state.token)
    },
    SET_ROLES: (state,roles) => {
        state.roles = roles;
    },
    SET_MODULE: (state, module) => {
        state.module = module;
    },
    SET_STREET_LIST: (state, streetList) => {
        state.streetList = streetList;
    }
};

const actions = {
    // 用户登录
    login({ commit }, userInfo) {
        const { username } = userInfo.username.trim();
        return new Promise((resolve,reject) => {
            let params = {
                username: userInfo.username,
                password: userInfo.password
            }
            setToken('token123')
            resolve()
            // loginByAccount({...params}).then(res => {
            //     const token = res.result.accessToken
            //     commit('SET_TOKEN', token)
            //     setToken(token)
            //     resolve()
            // }).catch(error => {
            //     reject('用户名或密码错误')
            // })
        })
    },
    // 获取用户角色信息
    getInfo({ commit, state }){
        return new Promise((resolve) => {
            setTimeout(() => {
                // const roles = state.token === 'sangjunyi' ? ['sangjunyi'] : ['no']
                const roles = 'sangjunyi'

                commit('SET_ROLES', roles)
                resolve({roles})
            }, 1000)
          
        })
    },
    // 重置令牌
    resetToken({ commit }){
        return new Promise(resolve => {
            commit('SET_TOKEN','')
            commit('SET_ROLES',[])
            removeToken();
            resolve()
        })
    },
    setModule({commit}, module) {
        return new Promise(resolve => {
            commit('SET_MODULE', module)
            resolve()
        })
    },
    setStreetList({commit}, streetList) {
        return new Promise(resolve => {
            commit('SET_STREET_LIST', streetList)
            resolve()
        })
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}