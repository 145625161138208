<template>
    <div id="app">
        <!-- <login/> -->
        <!-- <layout/> -->
        <a-config-provider :locale="locale">
            <router-view/>
        </a-config-provider>
    </div>
</template>

<script>
// import login from './components/login.vue'
// import layout from './components/layout/index'
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN';
import {queryModule, queryRegionNodeChild} from "@/api/region";
import _ from "lodash";
import moment from "moment";

export default {
    name: 'App',
    components: {
        // login
        // layout
    },
    async created() {
        moment.locale('zh')

        await queryModule(this.$store.getters.module.moduleCode).then(res => {
            this.$store.dispatch('user/setModule', res.result)
        })

        await queryRegionNodeChild({
            regionCode: this.$store.getters.module.extendsCode
        }).then(res => {
            this.$store.dispatch('user/setStreetList', _.map(res.result, value => {
                return {
                    label: value.regionName,
                    value: value.regionCode,
                }
            }))
        })
    },
    data() {
        return {
            locale: zhCN,
        }
    }
}
</script>

<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* text-align: center; */
    color: #2c3e50;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
}

/*滚动条美化*/
*::-webkit-scrollbar {
    width: 8px;
    height: 6px;
}

*::-webkit-scrollbar-track {
    background-color: #F1F1F1;
    -webkit-border-radius: 2em;
    -moz-border-radius: 2em;
    border-radius: 2em;
}

*::-webkit-scrollbar-thumb {
    background-color: #d8d9da;
    -webkit-border-radius: 2em;
    -moz-border-radius: 2em;
    border-radius: 2em;
}
</style>
