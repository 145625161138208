<template>
    <div style="height: 100%;width:100%;">
        <a-row style="height: 100%;" class="login-row">
            <a-col :xl="12" :lg="12" :md="24" :sm="24" :xs="24" class="xc-title">
                <span>
                苏州垃圾分类<br> 源头计量系统
                </span>
                <div id="particles" class="animate__animated animate__fadeIn"></div>
            </a-col>
            <a-col :xl="12" :lg="12" :md="24" :sm="24" :xs="24" class="ytx-login-container">
                    <span class="ytx-login-title">
                    用户登录
                    </span>
                    <a-form :form="form">
                    <a-form-item label="">
                        <a-input placeholder="用户名"  class="ytx-login-input" v-model="username"  v-decorator="['username', { rules: [{ required: true, message: '请输入用户名!' }] }]">
                            <a-icon slot="prefix" type="user" :style="{ fontSize: '23px'}"/>
                        </a-input><br/>
                    </a-form-item>
                    <a-form-item >
                        <a-input type="password" placeholder="密码"   class="ytx-login-input" v-model="password" v-decorator="['password', { rules: [{ required: true, message: '请输入密码!' }] }]">
                            <a-icon slot="prefix" type="lock" :style="{ fontSize: '23px'}"/>
                        </a-input><br/>
                    </a-form-item>
                    <a-form-item >
                        <a-input-group compact class="ytx-login-input-group">
                            <a-input placeholder="验证码" style="border-radius: 0px;" v-model="captcha" v-decorator="['captcha', { rules: [{ required: true, message: '请输入验证码!' }] }]" @pressEnter="check"/>
                            <a-tooltip placement="right" :defaultVisible="true">
                                <template slot="title">
                                    <span>点击刷新验证码</span>
                                </template>
                            <img :src="image.src" style="height:46px;" @click="getUserVerification"  />
                            </a-tooltip>
                        </a-input-group>
                    </a-form-item>
                <a-button type="primary"  class="ytx-login-button" @click="check">点击登录</a-button>

                    </a-form>
                <!-- <a-button type="primary"  class="ytx-login-button" @click="check">点击登录</a-button>  -->
            </a-col>
        </a-row>
        <a-row style="width:100%;position:absolute;bottom: 2%;text-align:center;">
            <a-col :span="24" style="text-decoration:none;color:initial;">Copyright  2020-2021 江苏英特信智能科技有限公司   ljflytjl.cn   All Rights Reserved.
                 <a href="https://beian.miit.gov.cn"  target="blank" style="text-decoration:none;color:initial;">苏ICP备2023026868号</a>
              </a-col>
        </a-row>
    </div>
</template>

<script>
import particlesJs from 'particles.js'
import particlesJson from '../utils/particles'
export default {
    components: {
    },
    data() {
        return{
            form:this.$form.createForm(this, { name: 'login-form' }),
            username: '',
            password: '',
            captcha: '',
            serialCode: '',
            remember: false,
            image: {
                src: '',
                code: ''
            }
        }
    },
    mounted() {
        this.init()
        this.getUserVerification()
    },
    methods: {
        getUserVerification() {
            this.$getUserVerificationPicture().then(res => {
                this.image.src = res.image
                this.image.code = res.serialCode
            })
        },
        check() {
            let that = this
            this.form.validateFields(err => {
                if (!err) {
                   this.$getAccToken(this.username, this.$md5(this.password).substring(0, 16), this.captcha, this.image.code).then(res => {
                       if(res.code != '0') {
                           that.getUserVerification()
                       }
                        this.$message.success('登陆成功')
                        if(res.result.accessToken) {
                            this.$getUserInfo().then(response => {
                            let user = {
                                ...response.result
                            }
                            localStorage.setItem('person-info', JSON.stringify({...user}))
                            setTimeout(() => {
                                this.$router.push({
                                    path: this.$route.query.redirect || '/websiteNavigation/websiteModule'
                                })
                            }, 1000)
                        })
                        } else {
                            this.$message.error('登录失败')
                        }
                   }).catch(() => {
                    //    this.$message.error('登录失败')
                   })
                } else {
                    this.$message.error('登录失败')
                }
            });
        },
        init() {
            particlesJS("particles", particlesJson);
            document.body.style.overflow = "hidden";
        },
        tologin() {
            // window.open('http://192.168.0.224:8080', '_blank')
           
        }
  },
}
</script>

<style lang="scss">
.login-row{
  width: 100%;
  height: 100%;
   background-image: url('../assets/login_background.png');
}

#particles{
  position: absolute;
  width: 100%;
  height: 100%;
  // background-image: url('../assets/image/earth.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
}
.xc-title{
  font-size: 65px;
  color: white;
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  // background-image: url('../assets/image/earth.jpg');
}

.ytx-login-container{
  height: 100%;
  position: relative;
  background: #fff;
  opacity: 0.9;
  .ytx-login-title{
    position: absolute;
    top: 23%;
    font-size: 3vw;
    color: #000;
    text-align: center;
    left: 23%;
    width: 400px;
  }
  .ant-form{
    position: absolute;
    top: 40%;
    left: 23%;
  }
  .ytx-login-button{
    // position: absolute;
    // top: 63%;
    // left: 23%;
     width: 400px;
    height:46px;
    font-size: 23px;
    margin-top: 20px;
  }
}
.welcome-btn{
  width: 400px;
  height: 50px;
  border: 3px solid;
}
@media screen and (min-width: 798px) {
.ytx-login-container{
    .ant-input-affix-wrapper{
      width: 400px !important;
      border-radius: 0px !important;
      .ant-input-prefix{
        left: 8px !important;
      }
      .ant-input{
        font-size: 23px;
        height: 46px;
      }
  }
  .ytx-login-input-group{
    .ant-input{
      width: calc(400px - 115px);
      border-radius: 0px;
      height: 46px;
      font-size: 23px;
    }

  }
  .ytx-login-button{
    // width: 400px;
    // height:46px;
    // font-size: 23px;
    // margin-top: 20px;
  }
}
}
@media screen and (max-width: 1000px) {
  .xc-title{
    display: none;
  }
  .ytx-login-container{
    width: 100%;
    background: #fff;
    .ytx-login-title{
      margin: auto;
    }
    .ytx-login-button{
    //   width: 90%;
      // height:46px;
      // font-size: 23px;
      // margin-top: 20px;
      // position: absolute;
      // top: 67%;
    //   left: 0%;
    }
  }

  .ant-input-affix-wrapper{
      width: 90% !important;
      border-radius: 0px !important;
      .ant-input-prefix{
        left: 8px !important;
      }
      .ant-input{
        font-size: 23px;
        height: 46px;
      }
  }
  .ytx-login-input-group{
    .ant-input{
      width: calc(90% - 115px);
      border-radius: 0px;
      height: 46px;
      font-size: 23px;
    }

  }

}

</style>
