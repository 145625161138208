import {getRegionNodeChildApi} from '@/api/division'
// import { getAccessTokenApi } from '@/api/getAccToken'
import {setAuthCode, setAuthState, setToken} from '@/utils/auth'
import {getAccessTokenApi, queryUserInfoApi} from '@/api/index'
import {getUserVerificationPicture} from '@/api/verification'
import {queryModule} from "@/api/region";
import {queryCenterGarbageTypeApi} from "@/api/home";

export default {
    /**
     *
     * @param {*} code
     * @returns
     * 全局使用---获取行政区树---传父节点的code
     */
    getRegionNodeChild: function (code) {
        return new Promise(function (resolve, reject) {
            getRegionNodeChildApi({regionCode: code || ''}).then(res => {
                resolve(res.result);
            })
                                                           .catch(() => {
                                                               reject([])
                                                           })
        })
    },
    /**
     * 全局使用---获取Token， 现已弃用
     *
     */
    getAccessToken: function (code1, uri, state) {
        console.log(code1, uri)
        return new Promise(function (resolve, reject) {
            const params = new FormData()
            params.append("client_id", 'c2')
            params.append("client_secret", '18351775621')
            params.append('grant_type', 'authorization_code')
            params.append('code', code1)
            params.append('state', state)
            // uri = encodeURIComponent('http://192.168.0.178:8080' + uri)
            params.append('redirect_uri', 'http://192.168.0.178:8081' + uri)
            // getAccessTokenApi({client_id: 'c1', client_secret: 'secret', grant_type: 'authorization_code', code: code1, redirect_uri: 'http://192.168.0.178:8081' + uri}).then(res => {
            getAccessTokenApi(params).then(res => {
                setToken(res.refresh_token)
                setAuthCode(code1)
                setAuthState(state)
                resolve(res)
            })
                                     .catch(() => {
                                         reject([])
                                     })
        })
    },
    getAccToken: function (username, password, captcha, serialCode) {
        return new Promise(function (resolve, reject) {  // getAccessTokenApi({client_id: 'c1', client_secret: 'secret', grant_type: 'authorization_code', code: code1, redirect_uri: 'http://192.168.0.178:8081' + uri}).then(res => {
            getAccessTokenApi({username: username, password: password, serialCode, captcha}).then(res => {
                setToken(res.result.accessToken)
                resolve(res)
            })
                                                                                            .catch(() => {
                                                                                                reject([])
                                                                                            })
        })
    },
    getUserInfo: function () {
        return new Promise(function (resolve, reject) {  // getAccessTokenApi({client_id: 'c1', client_secret: 'secret', grant_type: 'authorization_code', code: code1, redirect_uri: 'http://192.168.0.178:8081' + uri}).then(res => {
            queryUserInfoApi().then(response => {
                resolve(response)
            })
                              .catch(() => {
                                  reject([])
                              })
        })
    },
    getUserVerificationPicture: function () {
        return new Promise(function (resolve, reject) {  // getAccessTokenApi({client_id: 'c1', client_secret: 'secret', grant_type: 'authorization_code'})})
            getUserVerificationPicture().then(res => {
                resolve(res.result)
            }).catch(() => {
                reject([])
            })
        })
    },
    getCurrentModuleInfo: function (moduleCode) {
        return new Promise(function (resolve, reject) {
            queryModule(moduleCode).then(res => {
                resolve(res.result)
            })
                                   .catch((e) => {
                                       message.error(e.message)
                                   })
        })
    },
    queryCenterGarbageType: function (moduleCode) {
        return new Promise(function (resolve, reject) {
            queryCenterGarbageTypeApi({}, moduleCode).then(res => {
                resolve(res.result)
            }).catch((e) => {
                message.error(e.message)
            })
        })
    }
}
