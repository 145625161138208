import service from './network';


export function getRegionNodeChildApi(data) {
    return service.request({
        url: '/region/node/child',
        method: 'GET',
        params: data
    })
}

export function queryRegionList(data) {
    return service.request({
        url: '/region/query',
        method: 'post',
        data
    })
}

export function exportRegionApi(data) {
    return service.request({
        url: '/region/download',
        method: 'get',
        data,
        responseType: 'blob'
    })
}

export function uploadRegionApi(data) {
    return service.request({
        url: '/region/upload',
        method: 'post',
        data,
    })
}

export function updateRegionApi(data) {
    return service.request({
        url: '/region/update',
        method: 'put',
        data,
    })
}

export function deleteRegionApi(data) {
    return service.request({
        url: '/region/delete',
        method: 'delete',
        data,
    })
}

export function queryRegionTypeApi(data) {
    return service.request({
        url: '/region/type',
        method: 'get',
        data,
    })
}

export function addRegionTypeApi(data) {
    return service.request({
        url: '/region/update',
        method: 'put',
        data,
    })
}

