import {commonRoutes, routerMap} from '@/router/router'
import {queryUserInfo} from "@/api/user";
import _ from "lodash";

const state = {
    routers: commonRoutes,
    hasGetRules: false, //是否接口获取路由权限页面
    token: 'token123',
    componentRoutes: null,   //存放组件级别的权限，，暂不涉及
    componentOperation: {},

}

const getters = {
    getRouters(state) {
        return state.routers
    }
}

const mutations = {
    concatRules(state, data) {
        state.routers = data.concat(commonRoutes) //commonRoutes 再数组得到后面，即把接口返回的权限页面进行筛选之后，与通同不需要权限的页面进行
        state.hasGetRules = true
    },
    setComponentRoutes(state, data) {
        state.componentRoutes = data
    },
    setComponentOperation(state, data) {
        state.componentOperation = data
    }
}

const filterArr = (routes, rules) => { //将再本地配置的权限路由中筛选出后端接口返回的权限匹配满足后端接口返回需要的
    return routes.filter(item => {
        if (rules[item.name]) {
            if (item.children) {
                item.children = filterArr(item.children, rules)
            }
            return true
        } else {
            return false
        }
    })
}

const actions = {
    concatRoutes({commit}, rules) {
        return new Promise((resolve, reject) => {
            try {
                let arr = []
                if (Object.entries(rules).every(item => item[1]))  //将rules转换为二维数组 [[name, 1], [age, 2]]
                {
                    arr = routerMap
                } else {
                    arr = filterArr(routerMap, rules)
                }
                commit('concatRules', arr)
                resolve(state.routers)
            } catch (err) {
                reject(err)
            }
        })
    },
    getRules({commit}) {
        return new Promise(async (resolve, reject) => {
            let routes = (await queryUserInfo()).result.userRouts
            commit('setComponentRoutes', routes)
            let hash = {}
            _.forEach(routes, route1 => {
                _.forEach(route1.children, route2 => {
                    hash[route1.path + route2.path] = route2.operation
                })
            })
            commit('setComponentOperation', hash)
            let result = {
                page: {
                    viewport: true
                }
            }
            resolve(result)
        })
    }
}

export default {
    state,
    mutations,
    actions,
    getters
}
