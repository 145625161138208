import Vue from 'vue'
import Vuex from 'vuex'
// import axios from 'axios'
import router from './module/router'
import user from './module/user'
import perimission from './module/permission'
import getters from './module/getters'
Vue.use(Vuex)


export default new Vuex.Store({
    state: {

    },
    mutations: {

    },
    actions: {

    },
    modules: {
        router,
        user,
        perimission
    },
    getters
})
