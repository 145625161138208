import service from './network';

export function queryUserInfo() {
    return service.request({
        url: '/user/info',
        method: 'get',
    })
}

export function queryUserPhone() {
    return service.request({
        url: '/user/phone',
        method: 'get',
    })
}

export function sendPhoneCode() {
    return service.request({
        url: '/user/phone/notify',
        method: 'put',
    })
}

export function verifyPhoneCode(data) {
    return service.request({
        url: '/user/verification',
        method: 'post',
        data,
    })
}

/**
 * 用户管理综合查询
 * @param {*} data
 * @returns
 */
export function queryModuleUserApi(data) {
    return service.request({
        url: '/security/module/user/query',
        method: 'post',
        data,
    })
}

/**
 * 用户管理修改用户信息
 * @param {*} data
 * @returns
 */
export function updateModuleUserInfoApi(data) {
    return service.request({
        url: '/security/module/user/update',
        method: 'put',
        data,
    })
}

/**
 * 用户管理修改用户可用、禁用
 * @param {*} data
 * @returns
 */
export function updateModuleUserStatusApi(data) {
    return service.request({
        url: '/security/module/user/update/status/' + data,
        method: 'put',
    })
}

/**
 * 用户管理重置密码
 * @param {*} data
 * @returns
 */
export function resetUserPasswordApi(data) {
    return service.request({
        url: '/security/module/user/password/reset/' + data,
        method: 'put',
    })
}

/**
 * 用户管理查询用户已有模块
 * @param {*} data
 * @returns
 */
export function queryUserModuleListApi(data) {
    return service.request({
        url: '/module/user/list',
        method: 'get',
        params: data
    })
}

/**
 * 用户管理授予模块
 * @param {*} data
 * @returns
 */
export function grantUserModuleApi(data) {
    return service.request({
        url: '/security/module/user/grant/user/module',
        method: 'put',
        data
    })
}

/**
 * 用户管理授予模块
 * @param {*} data
 * @returns
 */
export function querySubUserModuleListApi(data) {
    return service.request({
        url: '/security/module/user/query/module/list',
        method: 'get',
        params: data
    })
}

/**
 * 用户管理删除用户
 * @param {*} data
 * @returns
 */
export function deleteUserApi(data) {
    return service.request({
        url: '/security/module/user/delete',
        method: 'delete',
        data
    })
}

export function updateUserPasswordApi(data) {
    return service.request({
        url: '/management/security/user/update/password',
        method: 'put',
        data,
    })
}

