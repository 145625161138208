import Vue from 'vue';
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import App from './App';
import axios from 'axios'
import store from '@/store/index.js'
import "babel-polyfill"
//引入router
import router from './router'
import '@/assets/icons'
import animated from 'animate.css'
import util from '@/utils/util'
import md5 from 'js-md5'
import '@/assets/fonts/iconfont.css'
import JsonExcel from 'vue-json-excel'
import {hasPermission} from "@/utils/permission";
import _ from "lodash";
// import VueSimplemde from 'vue-simplemde' // 引入文本编辑器
import 'simplemde/dist/simplemde.min.css'
import VueAMap from "vue-amap"; // 引入文本编辑器样式

Vue.prototype.$axios = axios;
Vue.prototype.$store = store

// Vue.use(infiniteScroll)

Vue.prototype.$getRegionNodeChild = util.getRegionNodeChild
Vue.prototype.$getAccToken = util.getAccToken
Vue.prototype.$getUserInfo = util.getUserInfo
Vue.prototype.$getUserVerificationPicture = util.getUserVerificationPicture
Vue.prototype.$getCurrentModuleInfo = util.getCurrentModuleInfo
Vue.prototype.$md5 = md5
Vue.use(VueAMap)
Vue.use(animated)
VueAMap.initAMapApiLoader({
    key: '2b204e0f00c23d4a9c09a4e35ba88150',
    plugin: ['AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.PolyEditor', 'AMap.CircleEditor', 'MarkerClusterer', 'AMap.Geolocation', 'AMap.Heatmap', 'AMap.Geocoder'],
    uiVersion: '1.0.11',
    v: '1.4.4'
})
//测试路由守卫
// import './router/perimission'

// Vue.component(Button.name, Button);
Vue.use(Antd)
Vue.config.productionTip = false;

Vue.component('downloadExcel', JsonExcel)

Vue.directive('permission', {
    inserted: function (el, binding, vnode) {
        let ops = _.split()

        if (!hasPermission(vnode.context.$route.path, vnode.context.$store.getters.componentOperation, binding.value)) {
            el.parentNode && el.parentNode.removeChild(el)
        }
    }
})

// Vue.use(VueSimplemde)

new Vue({
    router,  //注册到vue里
    render: h => h(App),
}).$mount('#app');