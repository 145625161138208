import axios from "axios";
import {message} from "ant-design-vue";
import store from '@/store'
import {getToken, removeToken} from '@/utils/auth'
import router from "@/router";

export const baseURL = 'http://114.219.56.10:18102/garbage-collect-server'
// export const baseURL = 'http://192.168.193.150:8081'

// 创建一个axios实例
var timeout;
// import axios from 'axios';
const service = axios.create({
    timeout: 60000,
    // baseURL: 'https://api.server.ljflytjl.cn/garbage-collect-server/',
    baseURL,

    method: 'post',
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
    }

})


// axios.defaults.baseURL = 'https://www.zhongshihudong.com/gateway  http://www.zhongshihudong.com:8091'
// 添加请求拦截器（Interceptors）
service.interceptors.request.use(function (config) {
    // 发送请求之前做写什么
    // if (store.getters.token) {
    config.headers['Authorization'] = getToken()
    config.headers['accesstoken'] = getToken()
    // }
    config.headers['moduleToken'] = store.getters.module.moduleCode
    return config;
}, function (error) {
    // 请求错误的时候做些什么
    return Promise.reject(error);
});

// 添加响应拦截器
service.interceptors.response.use(function (response) {
    // 对响应数据做点什么
    const res = response.data
    if (res.code === '401') {
        removeToken()
        router.push({path: '/login'})
        // 表示当前操作需要验证信息，未发现验证信息，则定位到信息核验界面
    } else if (res.code === "400") {
        message.error(res.message)
    } else if (res.code === "404") {
        message.error(res.message)
    }
    return res;
}, function (error) {
    // 对响应错误做点什么
    return Promise.reject(error);
});
export default service
