<template>
  <a-layout id="components-layout-demo-top-side-2">
    <a-layout-header class="header">
      <div class="logo" >
        <!-- <h3>应用服务平台</h3>
        <a-icon type="import" style="margin-left: 18%;line-height:45px;font-size: 25px" /> -->
        <span class="logo-title">应用服务平台

        </span>
      </div>

      <div class="user-wrapper" >
        <span class="user-wrapper-logout" style="margin-right: 22px;cursor:pointer;position:relative;" @click="showBellModal" >
            <a-badge :count="1" dot>
               <a-icon type="bell" />
            </a-badge>
        </span>
        <span class="user-wrapper-logout" @click="exitLogin">退出登录</span>
         <a-card title="消息通知" :bordered="false" style="width: 300px;position:absolute;top:10px;right:3%;top: 71px;z-index: 10;" v-if="showBell" class="animate__animated animate__zoomIn" >
          <p>暂无消息</p>
        </a-card>
      </div>
    </a-layout-header>
    <!-- <a-layout>
      <a-layout-sider width="200" style="background: #fff">
        <a-menu
          mode="inline"
          theme="dark"
          :style="{ height: '100%', borderRight: 0 }"
        >
          <a-sub-menu  v-for="(item, index) in menu" :key="index">
            <span slot="title"><span>{{item.meta.title}}</span></span>
            <a-menu-item  v-for="(subItem, subIndex) in item.children" :key="subItem.name" @click="goRouter(item, index, subItem, subIndex)" v-show="!subItem.hidden">{{subItem.meta.title}}</a-menu-item>
          </a-sub-menu>
        </a-menu>
      </a-layout-sider>
      <a-layout style="padding: 5px;box-sizing: border-box;">
        <a-layout-content
          :style="{ background: '#fff', padding: '10px', margin: 0, minHeight: '280px', boxSizing: 'border-box' }"
        >
         <transition>
            <router-view />
         </transition>
            <a-row style="width:100%;text-align:center;position:fixed;bottom:0%;background: rgba(0,0,0,0.2);">
            <a-col :span="24" style="text-decoration:none;color:initial;">Copyright  2020-2021 江苏英特信智能科技有限公司   ljflytjl.cn   All Rights Reserved.
               <a href="https://beian.miit.gov.cn"  target="blank" style="text-decoration:none;color:initial;">苏ICP备20047643号-3</a>
            </a-col>
          </a-row>
        </a-layout-content>
      </a-layout>
    </a-layout> -->
    <!-- <transition> -->
            <router-view />
         <!-- </transition> -->
            <a-row style="width:100%;text-align:center;position:fixed;bottom:3%;">
            <a-col :span="24" style="text-decoration:none;color:initial;">Copyright  2020-2021 江苏英特信智能科技有限公司   ljflytjl.cn   All Rights Reserved.
               <a href="https://beian.miit.gov.cn"  target="blank" style="text-decoration:none;color:initial;">苏ICP备2023026868号</a>
            </a-col>
          </a-row>
  </a-layout>
</template><script>
import { removeToken } from '@/utils/auth'

export default {
  name: 'layout',
  data() {
    return {
      menu: [],
      showBell: false,
      currentMenuKey: 0,
      currentSubKey: 0,
      collapsed: false,
      topMenu: [],
      leftMenu: [],
      currentTopMenuKey: 0,
      currentLeftMenuKey: 0
    };
  },
  mounted() {
    this.currentMenuKey = localStorage.getItem('menuKey') ? localStorage.getItem('menuKey') : 0
    this.currentSubKey = localStorage.getItem('subKey') ? localStorage.getItem('subKey') : 0
    this.getRouters()
  },
  methods: {
    exitLogin() {
      removeToken()
      this.$router.push({path: '/login'})
    },
    showBellModal() {
      this.showBell = !this.showBell
    },
    goRouter(route, index, subroute,subindex) {
      //update
      this.currentSubKey = subindex
      this.currentMenuKey = index
      console.log(route)
      let path = this.menu[this.currentMenuKey].path + '/' + this.menu[this.currentMenuKey].children[this.currentSubKey].path
      console.log(path)
      this.$router.push({path: path})
      localStorage.setItem('menuKey', this.currentMenuKey)
      localStorage.setItem('subKey', this.currentSubKey)
      localStorage.setItem('path', path)

    },
    // toggleTopMenu(route, index) {
    //   this.currentTopMenuKey = index
    //   this.leftMenu = this.topMenu[this.currentTopMenuKey].children

    // },
    getRouters() {
      let menu = this.$store.getters.getRouters
      //uopdate
      menu.forEach(item => {
         if (item.children !== undefined && item.children.length > 0) {
          this.menu.push(item)
        }
      })
      this.currentMenuKey = localStorage.getItem('menuKey') ? localStorage.getItem('menuKey') : 0
      this.currentSubKey = localStorage.getItem('subKey') ? localStorage.getItem('subKey') : 0
      let path = this.menu[this.currentMenuKey].path + '/' + this.menu[this.currentMenuKey].children[this.currentSubKey].path
      //详情页刷新保存当前带参路由
      if(this.$route.fullPath) {
        path = this.$route.fullPath
      }
      //
      this.$router.push({path: path})
      localStorage.setItem('menuKey', this.currentMenuKey)
      localStorage.setItem('subKey', this.currentSubKey)
      localStorage.setItem('path', path)

    }
  }
};
</script>

<style lang="scss">
.ant-layout{
    height: 100%;
    background: #fff;
    .ant-layout-header{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
}
.logo-title{
    display: flex;
    flex-direction: column;
    /* flex: 1; */
    justify-content: center;
    align-items: baseline;
    height: 100%;
    font-size: 20px;
    color: #fff;
}

#components-layout-demo-top-side-2 .logo {
  width: 15%;
  // height: 31px;
  // background: rgba(255, 255, 255, 0.2);
  margin: 16px 28px 16px 0;
  float: left;
  line-height: 32px;
  color: #fff;
  display: flex;
  h3{
    color: #fff;
  }
}

#components-layout-demo-top-side-2 .user-wrapper {
  width: 10%;
  display: flex;
  // height: 31px;
  // background: rgba(255, 255, 255, 0.2);
  margin: 16px 28px 16px 0;
}
.user-wrapper-logout{
    display: flex;
    flex-direction: column;
    /* flex: 1; */
    justify-content: center;
    align-items: center;
    height: 100%;
    font-size: 16px;
    color: #fff;
}
 .v-enter, .v-leave-to {
   opacity: 0;
   transition:opacity .3s;

 }
.v-enter-active, .v-leave-active{
  transition:opacity .3s;

}

</style>
